.header-row1 {
  background-color: #5E51A1;
  padding: 8px 24px;

  @media only screen and (min-width: 768px) {
    padding: 15px 40px;
  }
}

.header-title {
  font: 18px Poppins-Bold;
  color: white;
  @media only screen and (min-width: 576px) {
    font-size: 22px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 23px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 30px;
  }
}

.header-search-input,
.header-search-input:hover {
  height: fit-content;
  width: 150px;
  border-radius: 12px;
  padding: 4px;
  background-color: white;
  font: 14px Poppins-Medium;
  color: black;

  @media only screen and (max-width: 765px) {
    display: none;
  }

  @media only screen and (min-width: 750px) {
    font-size: 12px;
    height: 38px;
  }

  @media only screen and (min-width: 768px) {
    width: 180px;
    height: 38px;
  }

  @media only screen and (min-width: 992px) {
    width: 180px;
    font-size: 14px;
  }

  @media only screen and (min-width: 1200px) {
    width: 180px;
  }
}

.header-search-input-text {
  font: 14px Poppins-Medium;
  color: black;
}

.header-button,
.header-button:hover,
.header-button:focus {
  width: min-content;
  height: fit-content;
  padding: 11px;
  margin: 0px 10px;
  background-color: white;
  border-radius: 12px;
  border: unset;
  font: 14px Poppins-Bold;
  color: black;
  letter-spacing: 1.5px;
  display: none;

  @media only screen and (min-width: 768px) {
    display: initial;
  }
  @media only screen and (min-width: 750px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
}

.header-profile-pic {
  border-radius: 50%;
  height: 26px;
  

  @media only screen and (min-width: 576px) {
    height: 32px;
  }

  @media only screen and (min-width: 768px) {
    height: 42px;
  }
}

.header-row2 {
  padding: 0px;
  box-shadow: 0px 3px 6px #00000016;

  @media only screen and (min-width: 768px) {
    padding: 20px 0px;
  }
}

.header-popover-text {
  font: 12px Poppins-Semibold;
  text-align: center;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
}

.header-row2-text {
  font: 14px Poppins-Bold;
  text-align: center;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
}

.header-menu {
  font: 14px Poppins-Medium;
  color: black;
    border-radius: 8px !important;
  .ant-menu-submenu-title,
  .ant-menu-submenu,
  .ant-menu-item {
    margin: 0px !important;
  }

  .ant-menu-item-selected a {
    color: white !important;
  }
}

.header-menu-text {
  padding-left: 24px !important;
}


.home-page-popover-container-wrapper .ant-popover-inner {
  border-radius: 8px;
}

.header-language-button {
  width: min-content;
  height: fit-content;
  padding: 11px;
  margin: 0px 10px;
  background-color: white;
  border-radius: 12px;
  border: unset;
  font: 14px Poppins-Semibold;
  color: black;
  letter-spacing: 1.5px;

  @media only screen and (min-width: 576px) {
   padding: 8px;
  }
  @media only screen and (min-width: 750px) {
    font-size: 12px;
    padding: 11px
  }

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
}

.header-language-text {
  font: 14px Poppins-Semibold;
  text-align: start;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
}

.divider {
  opacity: 0.14;
  background-color: #707070;
  height: 1px;
}


@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;