/*funnel page first*/

@media screen and (min-width: 1px) {
  .copyright-text-wrapper {
    font-size: 16px;
  }
}

/*intermediate*/
@media screen and (min-width: 350px) {
  .copyright-text-wrapper {
    font-size: 16px;
  }
}

@media screen and (min-width: 576px) {
  .copyright-text-wrapper {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .copyright-text-wrapper {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .copyright-text-wrapper {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .copyright-text-wrapper {
    font-size: 18px;
  }
}

/*intermediate*/
@media screen and (min-width: 1300px) {
  .copyright-text-wrapper {
    font-size: 18px;
  }
}
@media screen and (min-width: 1500px) {
}

/*intermediate*/
@media screen and (min-width: 1700px) {
  .copyright-text-wrapper {
    font-size: 18px;
  }
}

/*intermediate*/
@media screen and (min-width: 1800px) {
  .copyright-text-wrapper {
    font-size: 20px;
  }
}

.ant-form-vertical .ant-form-item-label > label {
  font-size: 18px;
  font-family: Poppins-SemiBold;
  color: #34374d;
}
