.sign-up-body {
  padding: 30px 0px;
  background-color: white;

  @media only screen and (min-width: 992px) {
    min-height: 100vh;
  }

  @media only screen and (min-width: 1025px) {
    min-height: 95vh;
  }
}

.sign-up-header {
  font: 28px Poppins-SemiBold;
  color: black;
}

.sign-up-description {
  font: 14px Poppins-Light;
  color: #212526;
}

.sign-up-input {
  font: 14px Poppins-Light;
  height: 50px;
  border-radius: 5px;
  border-color: #b1b1b1;
  padding: 0px 30px;

  .ant-input {
    font: 14px Poppins-Light !important;
  }
}

.sign-up-input-new {
  font: 14px Poppins-Light;
  height: 50px;
  border-radius: 8px;
  border-color: #b1b1b1;
  padding: 0px 30px;

  .ant-input {
    font: 14px Poppins-Light !important;
  }
}

.sign-up-gender-select {
  .ant-select-selector {
    font: 14px Poppins-Light;
    padding: 25px 30px !important;
    align-items: center;
    text-align: start;
    border-radius: 5px !important;
    border-color: #b1b1b1 !important;
  }
  

  .ant-select-arrow {
    right: 30px;
  }

  &.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: lightgray !important;
  }

  &.ant-select-item.ant-select-item-option {
    padding-left: 30px;
    padding-right: 30px;
    text-align: start;
  }
}

.sign-up-gender-select-new {
  .ant-select-selector {
    font: 18px Poppins-Light;
    padding: 25px 30px !important;
    align-items: center;
    text-align: start;
    border-radius: 5px !important;
    border-color: #c2beda !important;
  }
  

  .ant-select-arrow {
    right: 30px;
  }

  &.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: lightgray !important;
  }

  &.ant-select-item.ant-select-item-option {
    padding-left: 30px;
    padding-right: 30px;
    text-align: start;
  }
}

.sign-up-checkbox {
  font: 14px Poppins-Light;
  color: #212526;
}

span.ant-checkbox-inner {
  transform: scale(1.1);
  border-radius: 50px;
  border: 1px solid black !important;
}

span.ant-checkbox-checked::after {
  border: unset;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
}

.sign-up-button {
  font: 14px Poppins-Bold;
  color: white;
  background-color: black;
  border: 3px solid black;
  border-radius: 15px;
  width: 100%;
  height: 50px;
}

.sign-up-button:hover,
.sign-up-button:focus {
  color: black;
  background-color: white;
  border-color: unset;
}

.sign-up-button-blue {
  font: 14px Poppins-Bold;
  color: white;
  background-color: #5E51A1 ;
  border: 3px solid #5E51A1 ;
  border-radius: 15px;
  width: 100%;
  height: 50px;
}

.sign-up-button-blue:hover,
.sign-up-button-blue:focus {
  color: #5E51A1 ;
  background-color: white;
  border-color: unset;
}

.sign-up-datepicker {
  font: 14px Poppins-Light;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border-color: #b1b1b1;
  padding: 20px 30px;
}

.sign-up-datepicker-new {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border-color: #c2beda;
  padding: 20px 30px;
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;