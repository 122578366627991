.business-speaking-banner {
  width: 100%;
}

.business-speaking-row {
  background-color: #f8f8f8;
  padding: 70px 0px;
  text-align: center;
}

.business-speaking-title {
  font: 30px Poppins-Bold;
  color: black;
}

.business-speaking-title-small {
  font: 20px Poppins-Bold;
  color: black;
}

.business-speaking-description {
  font: 18px Poppins-Medium;
  color: black;
  line-height: 30px;
}

.business-speaking-description-small {
  font: 15px Poppins-Medium;
  color: black;
  line-height: 30px;
}

.business-speaking-search-input,
.business-speaking-search-input:hover {
  width: 40%;
  border-radius: 12px;
  padding: 10px 30px;
  box-shadow: 0px 13px 25px #d0d0d080;
  font: 18px Poppins-Medium;
  color: black;

  @media screen and (max-width: 425px) {
    width: 80% !important;
  }
}

.business-speaking-table {
  .ant-spin-nested-loading {
    box-shadow: 0px 6px 6px #00000016;
    border-radius: 10px !important;
  }

  .ant-table,
  .ant-table-bordered {
    border-radius: 10px !important;
  }

  .ant-table-title {
    background-color: #e1e1e1;
    border-radius: 10px 10px 0px 0px;
  }

  .ant-table-footer {
    background-color: white;
    border-radius: 0px 0px 10px 10px;
  }

  .table-header {
    text-align: start;
    font: 22px Poppins-Bold;
    color: black;
    padding: 5px 20px;
    @media only screen and (min-width: 576px) {
      font-size: 33px;
    }
  }

  .table-header-small {
    text-align: start;
    font: 25px Poppins-Bold;
    color: black;
    padding: 5px 20px;
  }

  .table-footer {
    text-align: end;
    padding: 20px;
  }

  .table-footer-text {
    display: inline-block;
    text-align: end;
    font: 15px OpenSans-Regular;
    color: black;
  }

  .table-footer-text2 {
    text-align: end;
    display: inline-block;
    margin: 0px 10px;
    font: 15px OpenSans-Regular;
    color: black;
  }

  .table-footer-text-small {
    display: inline-block;
    text-align: end;
    font: 13px OpenSans-Regular;
    color: black;
  }

  .table-footer-text-small {
    display: inline-block;
    text-align: end;
    font: 13px OpenSans-Regular;
    color: black;
  }

  .table-footer-text2-small {
    text-align: end;
    display: inline-block;
    margin: 0px 10px;
    font: 13px OpenSans-Regular;
    color: black;
  }

  .table-footer-img {
    margin: 0px 10px;
  }

  .table-row-text {
    font: 20px OpenSans-Regular;
    color: black;
    padding: 20px 20px;
  }

  .table-row-text-small {
    font: 15px OpenSans-Regular;
    color: black;
    padding: 20px 10px;
  }
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;