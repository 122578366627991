.lesson-row {
  height: 87vh;
}

.lesson-left-sidebar {
  background-color: white;
  padding: 20px 0px 0px 0px;

  @media only screen and (min-width: 1200px) {
    background-color: black;
    // padding: 60px;
  }
}
.left-sidebar {
  background-color: white;
  @media only screen and (min-width: 1200px) {
    background-color: #5E51A1 ;
    // padding: 60px;
  }
}
// .ant-menu- > .ant-menu-item:hover,
// .ant-menu-inline > .ant-menu-submenu:hover,
// .ant-menu-inline > .ant-menu-item-active,
// .ant-menu-inline > .ant-menu-submenu-active,
// .ant-menu-inline > .ant-menu-item-open,
// .ant-menu-inline > .ant-menu-submenu-open,
// .ant-menu-inline > .ant-menu-submenu-selected {
//   color: white;
//   background-color: black ;
// }

.ant-menu-inline > .ant-menu-item-selected {
  border-radius: 0px !important;
}

.ant-menu-vertical > .ant-menu-item-selected {
  border-radius: 0px !important;
}

.ant-menu.ant-menu-light .ant-menu-item-selected.test {
  color: black !important;
  background-color: transparent !important; /*Overriden property*/
  border-radius: 0px !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #5E51A1;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
  color: #5E51A1 !important;
  background-color: white !important; /*Overriden property*/
}

.ant-layout-sider-trigger {
  background: #5E51A1;
}

.ant-menu-item-selected {
  color: white !important;
  background-color: black !important;
  border-radius: 8px !important;
}
.lesson-left-sidebar-img {
  display: inline-block;
}

.lesson-back-button,
.lesson-back-button:hover,
.lesson-back-button:focus {
  display: inline-block;
  font: 14px Poppins-Light;
  color: black;
  height: fit-content;
  padding: 0px;
  background-color: white;
  border: unset;

  @media only screen and (min-width: 1200px) {
    color: #c6c7c7;
    background-color: #5E51A1;
  }
}

.lesson-left-sidebar-title {
  display: none;
  font: 14px Poppins-Bold;
  color: #c6c7c7;

  @media only screen and (min-width: 1200px) {
    display: inherit;
    margin-top: 2.4em;
  }
}

.lesson-right-sidebar {
  width: 100%;
  text-align: center;
  left: 0;
  bottom: 0;

  @media only screen and (min-width: 1200px) {
    width: auto;
    padding-top: 40px;
    border-left: 2px solid #ededed;
  }
}


.lesson-continue-button,
.lesson-continue-button:focus {
  font: 14px Poppins-Bold;
  color: white;
  background-color: #5E51A1 ;
  border: 3px solid #5E51A1 ;
  border-radius: 12px;
  height: fit-content;
  padding: 15px 40px;
  letter-spacing: 2px;
  margin: 24px 0px;
  position: sticky;
  bottom: 0;

  @media only screen and (min-width: 1200px) {
    margin: unset;
    position: relative;
    bottom: unset;
  }
}

.lesson-continue-button:hover {
  border-color: unset;
  color: #5E51A1  !important;
  background-color: white;
}

.lesson-audio-button {
  height: 30px;
  @media only screen and (min-width: 576px) {
    height: auto;
  }
}

.lesson-container {
  padding: 30px;

  @media only screen and (min-width: 768px) {
    padding: 60px;
  }
}

.lesson-lesson-title {
  font: 23px Poppins-SemiBold;
  color: black;
}

.lesson-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lesson-scene-title {
  font: 25px Poppins-Bold;
  color: black;
  margin: 1.2em 0px;
  // text-decoration: underline;
}

.lesson-description {
  font: 21px Poppins-Medium;
  color: black;
}
.lesson-title-small {
  font: 20px Poppins-SemiBold;
  color: black;
}

.lesson-sub-title-small {
  font: 18px Poppins-Medium;
  color: black;
}

.lesson-description-small {
  font: 16px Poppins-Medium;
  color: black;
}

// Drag and Drop
.answer-box {
  display: inline-block;
  width: 150px;
  height: 22px;
  border: 1px dashed black;
  margin: 3px 10px -3px;
}

.lesson-answer-row {
  margin-top: 40px;
  text-align: center;

  .lesson-answer {
    margin: 5px 10px;
  }
}

.lesson-answer {
  height: fit-content;
  width: fit-content;
  padding: 5px 20px;
  border: 2px solid black;
  font: 18px Poppins-Medium;
  color: black;
  display: inline-block;
  margin: 0px 10px;
  background-color: white;

  &.correct {
    border-color: green;
    color: green;
  }

  &.wrong {
    border-color: red;
    color: red;
  }
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;