.shop-now-button-text {
    font-family: lato-regular;
    font-size: 12px;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    // padding-bottom: 50px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    text-align: center;
    display: block;
}

.home-title {
    font: 25px Poppins-Bold;
    color: black;
  }
@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;