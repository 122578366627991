@media screen and (min-width: 1px) {
  .funnel-page-topbar-title-wrapper {
    font-size: 12px;
  }

  .funnel-page-nav-button-wrapper.ant-btn {
    font-size: 10px;
  }

  .funnel-page-instruction-image-wrapper {
    width: 50px;
    height: auto;
  }

  .funnel-page-instruction-title-wrapper {
    font-size: 12px;
  }
  .funnel-page-instruction-desc-wrapper {
    font-size: 12px;
  }
}

/*intermediate*/
@media screen and (min-width: 350px) {
}

@media screen and (min-width: 576px) {
}

@media screen and (min-width: 768px) {
  .funnel-page-topbar-title-wrapper {
    font-size: 18px;
  }

  .funnel-page-nav-button-wrapper.ant-btn {
    font-size: 16px;
  }

  .funnel-page-instruction-image-wrapper {
    width: 80px;
    height: auto;
  }

  .funnel-page-instruction-title-wrapper {
    font-size: 16px;
  }
  .funnel-page-instruction-desc-wrapper {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .funnel-page-instruction-image-wrapper {
    width: 100px;
    height: auto;
  }

  .funnel-page-instruction-title-wrapper {
    font-size: 18px;
  }
  .funnel-page-instruction-desc-wrapper {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
}

/*intermediate*/
@media screen and (min-width: 1300px) {
}
@media screen and (min-width: 1500px) {
}

/*intermediate*/
@media screen and (min-width: 1800px) {
  .funnel-page-topbar-title-wrapper {
    font-size: 18px;
  }

  .funnel-page-nav-button-wrapper.ant-btn {
    font-size: 16px;
  }

  .funnel-page-instruction-image-wrapper {
    width: 100px;
    height: auto;
  }

  .funnel-page-instruction-title-wrapper {
    font-size: 18px;
  }
  .funnel-page-instruction-desc-wrapper {
    font-size: 18px;
  }
}
