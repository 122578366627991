.review-questions-row {
  height: 87vh;
}

.review-questions-left-sidebar {
  background-color: black;
  padding: 20px;

  @media only screen and (min-width: 1200px) {
    padding: 60px;
  }
}

.review-questions-back-button,
.review-questions-back-button:hover,
.review-questions-back-button:focus {
  display: inline-block;
  font: 14px Poppins-Light;
  color: #c6c7c7;
  height: fit-content;
  padding: 0px;
  background-color: black;
  border: unset;
}

.review-questions-left-sidebar-title {
  font: 14px Poppins-Bold;
  color: #c6c7c7;
  display: inline-block;
  margin: 0px 20px;

  @media only screen and (min-width: 1200px) {
    display: block;
    margin: 2.4em 0px 0px;
  }
}

.review-questions-right-sidebar {
  border-left: 2px solid #ededed;
  text-align: center;
  padding-top: 40px;
  left: 0;
  bottom: 0;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    width: auto;
  }
}

.review-questions-done-button,
.review-questions-done-button:focus {
  font: 14px Poppins-Bold;
  color: white;
  background-color: black;
  border: 3px solid black;
  border-radius: 12px;
  height: fit-content;
  width: 220px;
  padding: 15px 0px;
  letter-spacing: 2px;
}

.review-questions-done-button:hover {
  border-color: unset;
  color: black !important;
  background-color: white;
}

.review-questions-container {
  padding: 20px;

  @media only screen and (min-width: 576px) {
    padding: 40px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 60px;
  }
}

.review-questions-title {
  font: 25px Poppins-SemiBold;
  color: black;
}

.review-questions-table-row-text {
  font: 12px Poppins-Medium;
  color: black;
}

.review-questions-table {
  th.ant-table-cell {
    font: 12px Poppins-Medium;
    color: white;
    background-color: black;
  }

  .review-questions-img {
    margin: 5px 0px;
  }
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;