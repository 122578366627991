@media screen and (min-width: 1px) {
  .user-main-page-funnel-page-manage-funnel-title-wrapper {
    font-size: 16px;
    font-family: Poppins-SemiBold;
    padding: 16px;
    color: #34374d;
  }

  .user-main-page-funnel-page-manage-funnel-configure-title-wrapper {
    font-size: 16px;
    font-family: Poppins-SemiBold;
    padding: 16px;
    color: #34374d;
    text-align: center;
  }

  .user-main-page-funnel-page-created-publish-check-availability-desc-wrapper {
    font-size: 13px;
    font-family: Poppins-SemiBold;
    color: #34374d;
  }
  .user-main-page-funnel-page-created-publish-hostname-wrapper {
    font-size: 11px;
    font-family: Poppins-SemiBold;
    color: #34374d;
  }
  .user-main-page-funnel-page-created-steps-title-wrapper {
    font-size: 14px;
    font-family: Poppins-SemiBold;
    color: #40cbdc;
  }

  .user-main-page-funnel-page-created-steps-circle-wrapper {
    width: 24px;
    height: 24px;
  }

  .user-main-page-funnel-page-created-steps-text-wrapper {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #34374d;
  }

  .user-main-page-step-image-wrapper {
    width: 200px;
  }
  .user-main-page-steps-circle-wrapper {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: inline-block;
    background: #f5f5f5;
    vertical-align: middle;
  }
  .user-main-page-steps-text-wrapper {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #34374d;
  }

  .user-main-page-funnel-page-created-publish-title-wrapper {
    font-size: 14px;
    font-family: Poppins-SemiBold;
    color: #40cbdc;
    text-align: center;
  }

  .user-main-page-funnel-page-created-steps-image-wrapper {
    width: 200px;
  }
}

@media screen and (min-width: 350px) {
  .user-main-page-funnel-page-created-publish-hostname-wrapper {
    font-size: 13px;
  }
}

@media screen and (min-width: 576px) {
  .user-main-page-funnel-page-created-publish-check-availability-desc-wrapper {
    font-size: 13px;
  }
  .user-main-page-funnel-page-created-publish-hostname-wrapper {
    font-size: 14px;
  }

  .user-main-page-funnel-page-created-steps-title-wrapper {
    font-size: 18px;
  }

  .user-main-page-funnel-page-created-steps-image-wrapper {
    width: 300px;
  }

  .user-main-page-funnel-page-created-steps-circle-wrapper {
    width: 42px;
    height: 42px;
  }

  .user-main-page-funnel-page-created-steps-text-wrapper {
    font-size: 18px;
  }

  .user-main-page-step-image-wrapper {
    width: 300px;
  }

  .user-main-page-steps-circle-wrapper {
    width: 42px;
    height: 42px;
  }

  .user-main-page-steps-text-wrapper {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .user-main-page-funnel-page-created-publish-check-availability-desc-wrapper {
    font-size: 18px;
  }

  .user-main-page-funnel-page-created-publish-hostname-wrapper {
    font-size: 18px;
  }

  .user-main-page-funnel-page-created-publish-title-wrapper {
    font-size: 18px;
  }
}

.user-main-page-public-funnel-button-wrapper.ant-btn {
  color: white;
  /* background: linear-gradient(#60fdcd, #52b9e9); */
}

.user-main-page-public-funnel-button-wrapper.ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
}
