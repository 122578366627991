@media screen and (min-width: 1px) {
  .choose-ebook-model-steps-wrapper .ant-steps-item-title {
    font-size: 13px;
  }
}

@media screen and (min-width: 576px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
  .choose-ebook-model-steps-wrapper .ant-steps-item-title {
    font-size: 18px;
  }
}


.choose-ebook-model-button-wrapper {
  /* background: linear-gradient(#60fdcd, #52b9e9); */
  color: white;
}

.choose-ebook-model-button-wrapper.ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.choose-ebook-modal-img-wrapper {
  width: 80%;
  padding: 16px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.choose-ebook-modal-img-wrapper.selected {
  border: 2px solid blue;
}

.choose-ebook-modal-img-wrapper.not-selected {
  border: 2px solid transparent;
}


