@media screen and (min-width: 1px) {
  .select-ebook-model-steps-wrapper .ant-steps-item-title {
    font-size: 13px;
  }

  .select-ebook-model-button-wrapper.ant-btn {
    /* background: linear-gradient(#60fdcd, #52b9e9); */
    color: white;
    width: 100px;
    font-size: 15px;
  }
}

@media screen and (min-width: 576px) {
  .select-ebook-model-button-wrapper.ant-btn {
    width: 120px;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
  .select-ebook-model-steps-wrapper .ant-steps-item-title {
    font-size: 18px;
  }

  .select-ebook-model-button-wrapper.ant-btn {
    width: 150px;
    font-size: 20px;
  }
}

.select-ebook-model-button-wrapper.ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.modal-select-language-img {
  width: 80%;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
  background: white;
  border: 2px solid transparent;
}

.modal-select-language-img.selected {
  border: 2px solid blue;
}
.modal-select-language-img.not-selected {
  border: 2px solid transparent;
}
