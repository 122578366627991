.ebook-row {
  padding: 0px 0px 80px;
}

.ebook-title {
  font: 25px Poppins-Bold;
  color: black;
  padding: 30px;
  text-align: center;
  @media only screen and (min-width: 576px) {
    font-size: 35px;
    padding: 60px;
  }
}

.ebook-img-col {
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: start;
  }
}

.ebook-card-img {
  width: 70%;

  @media screen and (min-width: 576px) {
    width: 90%;
  }

  @media screen and (min-width: 680px) {
    width: 70%;
    margin-right: 20px;
  }
  @media screen and (min-width: 1024px) {
    width: 90%;
    margin-right: 20px;
  }
}

.ebook-card-title {
  font: 16px Poppins-Bold;
  color: black;
  text-align: center;
  margin-top: 1.2em;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    text-align: start;
    margin-top: 0px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
}

.ebook-card-description {
  font: 15px Poppins-Medium;
  color: black;
  margin-top: 1.2em;
  width: 100%;
  text-align: justify;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
    width: 90%;
  }
}

.ebook-button-col {
  text-align: center;
  margin-top: 2.4em;

  @media screen and (min-width: 768px) {
    margin-top: 2.4em;
  }

  @media screen and (min-width: 992px) {
    text-align: end;
    margin-top: 0px;
  }
}

.ebook-card-button {
  font: 20px Poppins-SemiBold;
  color: white;
  background-color: #5E51A1;
  border-radius: 10px;
  border: 3px solid #5E51A1;
  width: fit-content;
  height: fit-content;
  padding: 15px 20px;

  @media screen and (min-width: 576px) {
    font-size: 16px;
    padding: 10px;
  }

  @media screen and (min-width: 992px) {
    font-size: 16px;
    padding: 10px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
    padding: 15px 20px;
  }
}

.ebook-card-button:hover,
.ebook-card-button:focus {
  background-color: white;
  border-color: unset;
  color: #5E51A1;
}

.ebook-divider {
  height: 1px;
  background-color: #707070;
  margin: 40px 0px;
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;