@font-face {
  font-family: "Poppins-Bold";
  src: url(./fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url(./fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(./fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "PTSans-Regular";
  src: url(./fonts/PTSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Segoe-UI";
  src: url(./fonts/Segoe-UI.ttf) format("truetype");
}

@font-face {
  font-family: "Segoe-UI-Bold";
  src: url(./fonts/Segoe-UI-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: url(./fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: url(./fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Light";
  src: url(./fonts/Lato-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(./fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(./fonts/Montserrat-SemiBold.ttf) format("truetype");
}

/*more fonts for funnel page*/
@font-face {
  font-family: "Lato-Italic";
  src: url(./fonts/Lato-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(./fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Italic";
  src: url(./fonts/Roboto-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(./fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Italic";
  src: url(./fonts/OpenSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url(./fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Oswald-Regular";
  src: url(./fonts/Oswald-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald-Bold";
  src: url(./fonts/Oswald-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url(./fonts/SourceSansPro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Italic";
  src: url(./fonts/SourceSansPro-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url(./fonts/SourceSansPro-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url(./fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url(./fonts/Montserrat-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-Regular";
  src: url(./fonts/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-Italic";
  src: url(./fonts/Raleway-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-Bold";
  src: url(./fonts/Raleway-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "PTSans-Italic";
  src: url(./fonts/PTSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "PTSans-Bold";
  src: url(./fonts/PTSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lora-Regular";
  src: url(./fonts/Lora-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lora-Italic";
  src: url(./fonts/Lora-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Lora-Bold";
  src: url(./fonts/Lora-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url(./fonts/NotoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url(./fonts/NotoSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url(./fonts/NotoSans-Bold.ttf) format("truetype");
}

/* div.ant-modal-content {
  border-radius: 24px;
}

button.ant-modal-close {
  top: 6px;
  right: 7px;
}

div.ant-modal-body {
  padding: 24px 0px 0px 0px;
}
input.ant-input-lg{
  font-size: 0.8rem;
} */

div.ant-modal-content {
  border-radius: 8px 8px 8px 8px;
}

div.ant-modal-header {
  border-radius: 8px 8px 0px 0px;
}

.ebook-cover-snapshot-container.ant-row {
  width: 21cm;
  height: 29.7cm;
}

div.ant-select-dropdown {
  z-index: 1072;
}
div.ant-drawer {
  z-index: 1071;
}

/* @media screen and (max-width: 600px) {
  .ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item {
    padding-left: 0px;
  }
} */
