.lesson-paragraph-row {
  height: 87vh;
}

.lesson-paragraph-left-sidebar {
  background-color: black;
  padding: 60px;
}

.lesson-paragraph-left-sidebar-img {
  display: inline-block;
}

.lesson-paragraph-back-button,
.lesson-paragraph-back-button:hover,
.lesson-paragraph-back-button:focus {
  display: inline-block;
  font: 14px Poppins-Light;
  color: #c6c7c7;
  height: fit-content;
  padding: 0px;
  background-color: black;
  border: unset;
}

.lesson-paragraph-left-sidebar-title {
  font: 14px Poppins-Bold;
  color: #c6c7c7;
}

.lesson-paragraph-right-sidebar {
  border-left: 2px solid #ededed;
  padding: 60px;
  text-align: center;
}

.lesson-paragraph-continue-button,
.lesson-paragraph-continue-button:focus {
  font: 14px Poppins-Bold;
  color: white;
  background-color: black;
  border: 3px solid black;
  border-radius: 12px;
  height: fit-content;
  padding: 15px 40px;
  letter-spacing: 2px;
}

.lesson-paragraph-continue-button:hover {
  border-color: unset;
  color: black !important;
  background-color: white;
}

.lesson-paragraph-container {
  @media only screen and (min-width: 200px) {
    padding: 30px;
  }

  @media only screen and (min-width: 400px) {
    padding: 60px;
  }
}

.lesson-paragraph-title {
  font: 24px Poppins-SemiBold;
  color: black;
}

.lesson-paragraph-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lesson-paragraph-sub-title {
  font: 20px Poppins-Medium;
  color: black;
}

.lesson-paragraph-description {
  font: 17px Poppins-Medium;
  color: black;
}
.lesson-paragraph-title-small {
  font: 20px Poppins-SemiBold;
  color: black;
}

.lesson-paragraph-sub-title-small {
  font: 18px Poppins-Medium;
  color: black;
}

.lesson-paragraph-description-small {
  font: 15px Poppins-Medium;
  color: black;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  margin-top: 0.3rem;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}


@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;