@media screen and (min-width: 1px) {
  .template0001-funnel-page-title-wrapper {
    font-size: 16px;
  }
}

@media screen and (min-width: 375px) {

}

@media screen and (min-width: 450px) {

}

@media screen and (min-width: 576px) {

}

@media screen and (min-width: 768px) {
  .template0001-funnel-page-title-wrapper {
    font-size: 25px;
  }
}

@media screen and (min-width: 992px) {
  .edit-bottom-tooltip{
    margin-top:50px;
  }
}

@media screen and (min-width: 1200px) {
  .template0001-funnel-page-title-wrapper {
    font-size: 36px;
  }

  /* changes here */

}

@media screen and (min-width: 1500px) {

}

/*intermediate*/
@media screen and (min-width: 1700px) {

}

/*intermediate*/
@media screen and (min-width: 1800px) {

}


/* ebook edit drawer */
.ant-col.template0001-img-col-wrapper {
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ant-col.template0001-img-col-wrapper.selected {
  border: 2px solid blue;
}

.ant-col.template0001-img-col-wrapper.not-selected {
  border: 2px solid rgba(0, 0, 0, 0.2);
}

/*new header styling*/

/*eBook container*/
.template0001-ebook-cover-container.public {
  cursor: auto;
}
.template0001-ebook-cover-container.non-public {
  cursor: pointer;
}

.template0001-ebook-cover-container.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-ebook-cover-container.non-public.not-selected {
  box-shadow: none;
}

.template0001-ebook-cover-container.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

.title-and-outline-container.ant-row.selected {
  border: 1px solid transparent;
}
.title-and-outline-container.ant-row.not-selected {
  border: 1px solid grey;
}

/*header*/
.template0001-header-textarea.public textarea {
  cursor: auto;
}
.template0001-header-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-header-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-header-textarea.non-public.not-selected {
  box-shadow: none;
}

.template0001-header-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*outline 1*/
.template0001-outline1-textarea.public textarea {
  cursor: auto;
}

.template0001-outline1-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-outline1-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-outline1-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-outline1-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*outline 2*/
.template0001-outline2-textarea.public textarea {
  cursor: auto;
}

.template0001-outline2-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-outline2-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-outline2-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-outline2-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*outline 3*/
.template0001-outline3-textarea.public textarea {
  cursor: auto;
}

.template0001-outline3-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-outline3-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-outline3-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-outline3-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*outline 4*/
.template0001-outline4-textarea.public textarea {
  cursor: auto;
}

.template0001-outline4-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-outline4-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-outline4-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-outline4-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*outline 5*/
.template0001-outline5-textarea.public textarea {
  cursor: auto;
}

.template0001-outline5-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-outline5-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-outline5-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-outline5-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*bottom desc 1*/
.template0001-bottomDesc1-textarea.public textarea {
  cursor: auto;
}

.template0001-bottomDesc1-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-bottomDesc1-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-bottomDesc1-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-bottomDesc1-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*bottom desc 2*/
.template0001-bottomDesc2-textarea.public textarea {
  cursor: auto;
}

.template0001-bottomDesc2-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-bottomDesc2-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-bottomDesc2-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-bottomDesc2-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

/*bottom desc 3*/
.template0001-bottomDesc3-textarea.public textarea {
  cursor: auto;
}

.template0001-bottomDesc3-textarea.non-public textarea {
  cursor: pointer;
}

.template0001-bottomDesc3-textarea.non-public.selected {
  box-shadow: 0 0 10px white;
}
.template0001-bottomDesc3-textarea.non-public.not-selected {
  box-shadow: none;
}
.template0001-bottomDesc3-textarea.non-public.not-selected:hover {
  box-shadow: 0 0 10px white;
}

textarea::placeholder {
  color: #fff;
}

.edit-bottom-tooltip{
  margin-top: 0px;
}
