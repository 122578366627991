@media screen and (min-width: 1px) {
  .user-main-page-free-youtube-title-wrapper {
    font-size: 14px;
    font-family: Poppins-SemiBold;
    color: #34374d;
  }

  .user-main-page-free-youtube-desc-wrapper {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #34374d;
  }
}

@media screen and (min-width: 576px) {
  .user-main-page-free-youtube-title-wrapper {
    font-size: 18px;
  }

  .user-main-page-free-youtube-desc-wrapper {
    font-size: 18px;
  }
}
