.exam-row {
  height: 87vh;
}

.exam-left-sidebar {
  background-color: black;
  padding: 60px;
  
}

.exam-left-sidebar-img {
  display: inline-block;
}

.exam-back-button,
.exam-back-button:hover,
.exam-back-button:focus {
  display: inline-block;
  font: 14px Poppins-Light;
  color: #c6c7c7;
  height: fit-content;
  padding: 0px;
  background-color: black;
  border: unset;
}

.exam-back-button-small,
.exam-back-button-small:hover,
.exam-back-button-small:focus {
  display: inline-block;
  font: 14px Poppins-Light;
  color: black;
  height: fit-content;
  padding: 0px;
  background-color: white;
  border: unset;
}

.exam-left-sidebar-title {
  font: 14px Poppins-Bold;
  color: #c6c7c7;
}

.exam-right-sidebar {
  border-left: 2px solid #ededed;
  text-align: center;
}

.exam-continue-button,
.exam-continue-button:focus {
  font: 14px Poppins-Bold;
  color: white;
  background-color: black;
  border: 3px solid black;
  border-radius: 12px;
  height: fit-content;
  width: 220px;
  padding: 15px 0px;
  letter-spacing: 2px;
}

.exam-continue-button:hover {
  border-color: unset;
  color: black !important;
  background-color: white;
}

.exam-container {
  padding: 60px;
  text-align: "center"
}

.exam-title {
  font: 25px Poppins-SemiBold;
  color: black;
  // text-align: center;
}

.exam-sub-title {
  font: 20px Poppins-Medium;
  color: black;
  // text-align: center;
}

// .exam-description {
//   font: 18px Poppins-Medium;
//   color: black;
  
// }

.exam-review-table-row-text {
  font: 12px Poppins-Medium;
  color: black;
}

.exam-table {
  th.ant-table-cell {
    font: 12px Poppins-Medium;
    color: white;
    background-color: black;
  }

  .exam-review-img {
    margin: 5px 0px;
  }
}
@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;