.free-trial-body {
  padding: 50px 0px;

  @media only screen and (min-width: 992px) {
    min-height: 100vh;
  }

  @media only screen and (min-width: 1025px) {
    min-height: 95vh;
  }
}


.free-trial-card {
  width: 90% !important;
  padding: 20px 0px;
  text-align: center;
   
  @media screen and (min-width: 576px) {
    width: 80% !important;
    padding: 30px 0px;
  }

  @media screen and (min-width: 768px) {
    width: 50% !important;
    padding: 40px 0px;
  }

  @media screen and (min-width: 1024px) {
    width: 50% !important;
    padding: 50px 0px;
  }
}

.free-trial-title {
  font: 20px Poppins-SemiBold;
  color: black;
  
  
  @media screen and (min-width: 576px) {
    font-size: 23px
  }

  @media screen and (min-width: 768px) {
    font-size: 25px;
  }
}

.free-trial-description {
  font: 14px Montserrat-Medium;
  color: black;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
}

.freetrial-continue-button,
.freetrial-continue-button:focus {
  font: 10px Montserrat-Bold;
  color: white;
  background-color: black;
  border: 3px solid black;
  border-radius: 15px;
  width: 70px;
  height: 50px;


  @media screen and (max-width:425px) {
    width: 100%  !important;
  }
 

  @media screen and (min-width: 768px) {
    width: auto !important;
    font-size: 14px
  }

  @media screen and (min-width: 1024px) {
    width: auto !important;
    font-size: 14px
  }

  
}

.freetrial-continue-button:hover {
  color: black;
  background-color: white;
  border-color: unset;
}

.choice {
  height: fit-content;
  margin: 20px;
  padding: 20px;
  width: 100%;
  font: 14px Montserrat-Medium;
  color: black;

  .choice-text {
    font: 14px Montserrat-SemiBold;
    display: inline-block
  }
}

.radio-group{
  width: 100% !important;
  text-align: center;

  @media screen and (max-width:425px) {
    width: 85%  !important;
  }
   

  @media screen and (min-width: 1024px) {
    width: 80% !important;
  
  }
}



@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;